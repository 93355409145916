import React from "react";
import drone from "../../../static/enforcement/drone.jpg";
import hp from "../../../static/partners/hp.png";
import oracle from "../../../static/partners/oracle.png";
import law4 from "../../../static/enforcement/law4.png";

import case4 from "../../../static/solutions/case4.jpg";
import antenna from "../../../static/solutions/antenna.jpg";
import policeLights from "../../../static/solutions/police-lights.jpg";
import cctv from "../../../static/solutions/cctv.jpg";
import network from "../../../static/solutions/network.jpg";
import motorola from "../../../static/partners/motorola.png";

import Layout from "../../components/Layout/Layout";

const LawEnforcement = () => {
  return (
    <Layout>
      <div className="page ">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-paragraph">INDUSTRY</h1>
            <h1 className="section-title">Law Enforcement & Customs</h1>
            <p className="section-description">
              Our Company stands behind our brave law enforcement officers and
              supports them with all the best tech currently available.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // textAlign: 'center',
            }}
          >
            <img className="section-image" src={law4} alt="" />
          </div>
        </div>
        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Hi-Tech Law Enforcement</h1>
            </div>
          </div>
          <div className="row page__section --grid-1-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                S&T Moldova is working closely with major suppliers of
                specialized equipment for police task forces.
              </p>
              <p className="section-description">
                Our services include consulting, automating processes,
                implementing new technologies and enhancing cross-sector
                collaboration in order to improve healthcare standards and
                consumer satisfaction.
              </p>
              <p className="section-description">
                We help turn the vision of a better, more efficient and more
                affordable healthcare system into reality by documenting,
                technology.
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img className="section-description-image" src={drone} alt="" />
            </div>
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-2-1 --align-left">
            <div>
              <h1 className="section-sub-title">Customs Service</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={case4} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Law Enforcement</span>
                  <p className="section-title-small --align-left ">
                    Asycuda World
                  </p>
                  <p className="section-description --align-left ">
                    Our Company maintans and extends Asycuda - the central
                    software that processes all customs operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={cctv} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Law Enforcement</span>
                  <p className="section-title-small --align-left ">
                    Automated risk assessments
                  </p>
                  <p className="section-description --align-left ">
                    Our teams implement, maintain and extend image recognition
                    platforms and other specialized equipment for risk
                    assessments and threat detection.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={network} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Law Enforcement</span>
                  <p className="section-title-small --align-left ">
                    Critical infrastructure
                  </p>
                  <p className="section-description --align-left ">
                    We build and support the critical infrastructure the
                    supports customs operations. We monitor, neutralize problems
                    andgit constantly extend and improve it with newest
                    technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block--white">
          <div className="row  page__section --grid-2-1 --align-left">
            <div>
              <h1 className="section-sub-title">
                Police Departments & Border Police
              </h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1 --align-left">
            <div className="tile-container">
              <div className="studies-tile --align-left height-45">
                <img className="section-image" src={antenna} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Law Enforcement</span>
                  <p className="section-title-small --align-left ">
                    Critical communications
                  </p>
                  <p className="section-description --align-left ">
                    Together with our parters from Motorola, we implement and
                    support critical radio communications that our officers use
                    daily.
                  </p>
                  {/* <a href="/" className="learn-more">
                    LEARN MORE
                  </a> */}
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="studies-tile --align-left height-45">
                <img className="section-image" src={policeLights} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Law Enforcement</span>
                  <p className="section-title-small --align-left ">
                    Planning and Monitoring
                  </p>
                  <p className="section-description --align-left ">
                    Using specialized hardware and software we help our police
                    force plan and monitor their activities ensuring an optimal
                    and efficient deployment of resources.
                  </p>
                  {/* <a href="/" className="learn-more">
                    LEARN MORE
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block --white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Our Partners</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "4rem" }}
                    className="partners-image"
                    src={motorola}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Motorola is the leading global supplier of TETRA equipment
                    and is our partner in Moldova. We supply Motorola equipment
                    to the Police, Border Police, Customs, 112 service, and
                    others.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "7rem" }}
                    className="partners-image"
                    src={hp}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Through our partner HPE, we supply, configure and maintain
                    top of the line servers, storage and networking equipment
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "2rem" }}
                    className="partners-image"
                    src={oracle}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Oracle databases are present in the wide majority of
                    government organizations and our specialists are always
                    ready to offer professional assistance in operating the
                    critical stores of data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LawEnforcement;
